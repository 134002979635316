<template>
  <div class="flex h-screen w-screen items-center justify-center bg-gray-50 background">
    <div class="z-10 w-full max-w-md overflow-hidden rounded-2xl border border-gray-100 shadow-xl ">
      <div
        class=" flex flex-col items-center justify-center space-y-3 border-b border-gray-200 bg-white px-4 py-6 pt-8 text-center sm:px-16">
        <a href="https://dub.sh">
          <img src="../../public/img/logo.png" alt="Dub.sh logo" class="h-10 w-10" />
        </a>
        <h3 class="text-xl font-semibold">Sign In</h3>
        <p class="text-sm text-gray-500">
          Use your email address to sign in.
        </p>
      </div>
      <form @submit="loginWithEmailAndPassword" class="flex flex-col space-y-4 bg-gray-50 px-4 py-8 sm:px-16">
        <div>
          <label htmlFor="email" class="block text-xs text-gray-600">
            EMAIL ADDRESS
          </label>
          <input id="email" name="email" type="email" placeholder="panic@thedis.co" autoComplete="email" required
            v-model="this.email"
            class="mt-1 block w-full appearance-none rounded-md border border-gray-300 px-3 py-2 placeholder-gray-400 shadow-sm focus:border-black focus:outline-none focus:ring-black sm:text-sm" />
        </div>
        <div>
          <label htmlFor="email" class="block text-xs text-gray-600">
            Password
          </label>
          <input id="password" name="password" type="password" placeholder="Password" autoComplete="password" required
            v-model="this.password"
            class="mt-1 block w-full appearance-none rounded-md border border-gray-300 px-3 py-2 placeholder-gray-400 shadow-sm focus:border-black focus:outline-none focus:ring-black sm:text-sm" />
        </div>
        <div v-if="!!this.message"
          class="p-4 mb-4 text-sm text-red-800 rounded-lg bg-red-50 dark:bg-gray-800 dark:text-red-400" role="alert">
          <span class="font-medium">Oooops! </span>{{ this.message }}
        </div>
        <button @click="loginWithEmailAndPassword"
          class="flex h-10 w-full items-center justify-center rounded-md border text-sm transition-all focus:outline-none border-black bg-black text-white hover:bg-white hover:text-black">
          <p>Sign In</p>
        </button>
        <p class="text-center text-sm text-gray-600">
          Don't have an account?
          <a href="/register" class="font-semibold text-gray-800">
            Sign up
          </a>
          for free.
        </p>
      </form>
    </div>
  </div>
</template>

<script>
export default {
  name: "SignInView",
  data() {
    return {
      email: "",
      password: "",
      message: "",
      isPasswordVisible: false,
    };
  },
  methods: {
    async loginWithEmailAndPassword(event) {
      event.preventDefault();

      const formData = {
        email: this.email,
        password: this.password,
      };

      try {
        await this.$store.dispatch("signInWithEmailAndPassword", formData);
      } catch (error) {
        this.message = error.message;
      }
    },
  },
};
</script>

<style>
@import '../styles/background.css';
</style>