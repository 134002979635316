import { firestore } from "@/firebase";
import {
  getFirestore,
  collection,
  query,
  where,
  doc,
} from "firebase/firestore";
import { DataService } from '@/dataService/analyticsService';

function getLabels (date, timeframe) {
  var dateObject = new Date(date)
  if (timeframe == 0) {
    return dateObject.getHours() + 2 + ":" + dateObject.getMinutes() + '0'
  }
  if (timeframe == 364) {
    return dateObject.toLocaleString('default', { month: 'short' }).slice(0,-1)
  }
  if (timeframe == 6) {
    return dateObject.toLocaleString('default', { weekday: 'short' }).slice(0,-1)
  }
  if (timeframe == 29) {
    return dateObject.getDate() + '/' + dateObject.getMonth();
  }
}

function getDate(date) {
  // Date object
  //const date = new Date();
  let currentDay = String(date.getDate()).padStart(2, '0');
  let currentMonth = String(date.getMonth() + 1).padStart(2, "0");
  let currentYear = date.getFullYear();
  // we will display the date as DD-MM-YYYY 
  let currentDate = `${currentYear}-${currentMonth}-${currentDay}`;
  return currentDate;
}

export default {
  state: {
    linkList: null,
    analytics: null,
    tileName: null,
  },
  mutations: {
    fetchLinkList(state, linkList) {
      state.linkList = linkList;
    },

    setTileName(state, name) {
      console.log(name)
      state.tileName = name
    },

    setAnalytics(state, analytics) {
      console.log(analytics)
      state.analytics = analytics
    }
  },




  actions: {

    

    async analyticsGetVisits({commit}, params) {
      const currentDate = getDate(new Date())
      const earlierDate = getDate(new Date(new Date().setDate(new Date().getDate() - params.timeframe)))

      try {
        const query = await DataService.get('https://api.tinybird.co/v0/pipes/kpis.json?date_from=' + earlierDate + '&date_to=' + currentDate + '&path=/link/' + params.url)
        var analytics = {'labels': [], 'datasets': []}
        analytics['datasets'][0] = []
        analytics['datasets'][0]['data'] = []
        analytics['total'] = 0

        for (const doc of query.data.data) {
          console.log(doc)
          analytics['labels'].push(getLabels(doc.date, params.timeframe))
          analytics['datasets'][0]['data'].push(doc.pageviews)
          analytics['datasets'][0]['label'] = 'Scans'
          analytics['total'] = analytics['total'] + doc.pageviews
        }
        commit("setAnalytics", analytics)


      } catch (err) {
        console.log(err)
      }
    },

    async analyticsGetTotalVisits({commit}, payload) {
      console.log(payload.url   )
      try {
        const query = await DataService.get('https://api.tinybird.co/v0/pipes/top_pages.json?limit=50&path=/link/' + payload.url);
        console.log(query.data.data[0])
        if (typeof(query.data.data[0]) != 'undefined') {
            return query.data.data[0].hits
          } else {
            return 0
          }
        } catch (err) {
        console.log(err);
      }
    },

    async fetchLink({ commit, dispatch, rootState }, id) {
      console.log(id)
      const docRef = firestore.doc(getFirestore(), "links", id);
const docSnap = await firestore.getDoc(docRef)
return docSnap.data()
      },

    async fetchLinkList({ commit, dispatch, rootState }) {
      const userUid = rootState.auth.user.uid;
      let linksArray = [];
      const queryRef = query(
        collection(getFirestore(), "links"),
        where("owner", "==", userUid)
      );
      await firestore
        .getDocs(queryRef)
        .then(async (response) => {
          for (const doc of response.docs) {
            const elementData = doc.data();
            elementData.id = doc.id;
            elementData.scans = await dispatch('analyticsGetTotalVisits', { url: doc.id.replace(/\s+/g, '') })
            linksArray.push(elementData)
          }})
          commit("fetchLinkList", linksArray);
        },

    async addNewLink({ rootState, dispatch }, newLink) {
      const userUid = rootState.auth.user.uid;
      newLink.owner = userUid;
      newLink.status = 'active'
      newLink.updated = Math.ceil(Date.now()/1000)

      const collectionRef = collection(getFirestore(), "links");
      await firestore
        .addDoc(collectionRef, newLink)
        .then(() => dispatch("fetchLinkList"))
        .catch(() => {
          throw Error("Error due add new ToDo.");
        });
    },

    async changeLink({ rootState, dispatch }, link) {
      const collectionRef = collection(getFirestore(), "links");
      link.updated = Date.now()/1000
      await firestore
        .setDoc(doc(collectionRef, link.id), link)
        .then(() => dispatch("fetchLinkList"))
        .catch(() => {
          throw Error("Error due add new ToDo.");
        });
    },

    async updateLink({ dispatch }, { id, link }) {
      const documentRef = doc(getFirestore(), "links", id);
      await firestore
        .updateDoc(documentRef, {
          isChecked: !currentCheckState,
        })
        .then(() => dispatch("fetchLinkList"))
        .catch(() => {
          throw Error("Error due check ToDo.");
        });
    },

    async deleteLink({ dispatch }, id) {
      const documentRef = doc(getFirestore(), "links", id);
      await firestore
        .deleteDoc(documentRef)
        .then(() => dispatch("fetchLinkList"))
        .catch(() => {
          throw Error("Error due delete ToDo.");
        });
    },
  },
};
