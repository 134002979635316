import { initializeApp } from "firebase/app";
import * as auth from "firebase/auth";
import * as firestore from "firebase/firestore";

const firebaseConfig = {
  apiKey: "AIzaSyAN-KxKarwSCJOkPvj1J4c55p6UE9A62fA",
  authDomain: "taptileslink.firebaseapp.com",
  databaseURL: "https://taptileslink-default-rtdb.europe-west1.firebasedatabase.app",
  projectId: "taptileslink",
  storageBucket: "taptileslink.appspot.com",
  messagingSenderId: "853742160078",
  appId: "1:853742160078:web:5a9b5ee84316ddc15d289f",
};

const app = initializeApp(firebaseConfig);

const auth2 = auth.getAuth(app);

auth.setPersistence(auth2, auth.browserLocalPersistence)

export { app, auth, firestore };
