import { createRouter, createWebHistory } from "vue-router";
import SignInView from "@/views/SignInView";

import store from "@/store";
import { getAuth } from "firebase/auth";

const auth = getAuth();

const routes = [
  {
    path: "/",
    name: "index",
    component: () => import("@/views/Index"),
  },

  {
    path: "/sign-in",
    name: "sign-in",
    component: SignInView,
  },

  {
    path: "/link/:id",
    name: "link",
    component: () => import("@/views/Link"),
  },

  {
    path: "/sign-up",
    name: "sign-up",
    component: () => import("@/views/SignUpView"),
  },
  {
    path: "/home",
    name: "home",
    component: () => import("@/views/HomeView"),
    meta: { requiresAuth: true },
  },
  {
    path: "/settings",
    name: "settings",
    component: () => import("@/views/SettingsView"),
    meta: { requiresAuth: true },
  },
  {
    path: "/analytics/:id",
    name: "analytics",
    component: () => import("@/views/TileAnalyticsView"),
    meta: { requiresAuth: true },
  },
];

const router = createRouter({
  history: createWebHistory(process.env.BASE_URL),
  routes,
});

router.beforeEach((to, from, next) => {
  const requiresAuth = to.matched.some((x) => x.meta.requiresAuth);
  console.log(requiresAuth)
  if (requiresAuth) {
    getAuth().onAuthStateChanged(function (user) {
      if (user) {
        store.commit('signIn', user)
        next();
      } else {
        next("/sign-in");
        return
      }
    });
  } else {
    next()
  }
});

export default router;
